import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { LiveMusic } from "./components/LiveMusic/LiveMusic";
import { Karaoke } from "./components/Karaoke/Karaoke";
import { Poker } from "./components/Poker/Poker";
import { Pong } from "./components/Pong/Pong";
import { About } from "./components/About/About";
import { Socials } from "./components/Socials/Socials";
import BackToTopButton from "./components/TopButton/TopButton";
import CardDisplay from "./components/CardDisplay/CardDisplay";
import { League } from "./components/League/League";
import { Slideshow } from "./components/Slideshow/Slideshow";

function App() {
  return (
    <>
      <div className=" headerNav">
        <Header />

        <Slideshow />

        <LiveMusic />

        <Karaoke />
      
        <Pong />

        <CardDisplay />

        <Poker />

        <League />

        <About />

        <Socials />

        <Footer />

        <BackToTopButton />
      </div>
    </>
  );
}

export default App;
