import React from "react";

/*
View component that displays the footer
*/

export function Footer(){
    return(
        <div className="footer text-light">
        <footer className="container-fluid text-center App header text-light">
            
            <hr />
            <small>
                Copyright &copy; 2023 Reactive Elements Design
            </small>
        </footer>
        </div>

    )
}