import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export const Poker = () => {
  return (
    <header id="poker" className="p-4 rounded-5 pokerBackgroundImg">
      <div className="container text-end">
        <h1 className="display-5 fw-bold">
          Poker <img src="./poker2.png" width="128" height="128" alt="Poker Icon" />
        </h1>
        <div className="row">
          <div className="col-md-6">
            <img src="./poker.jpg" className="img-fluid" alt="Poker" />
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <div>
              🍻 Enjoy the thrill of the game while sipping on your favorite
              drinks from our well-stocked bar. Our friendly staff is here to
              ensure you have everything you need for an unforgettable poker
              experience.
            </div>
            <div className="container-fluid text-center">
              <h2>Monthly Poker Tournament On October 27th @ 1PM</h2>
              <div>
                <div className="fw-bold">Sign-Ups:</div>
                <div>Sign-ups will begin this Saturday, October 27th after 7 PM.</div>
                <div>The bar will have sign-up sheets available.</div>
                <div>Sign up before noon on the day of the event to get an extra 3k in chips.</div>
              </div>
              <div>
                <div className="fw-bold">Monthly Poker Tournament Buy-Ins:</div>
                <div>$50 buy-in for a 25k starting stack.</div>
                <div>Re-entries are allowed until 2:30 PM.</div>
                <div>Late entries can buy in until 2:30 PM.</div>
                <div>Two optional add-ons at the end of the first hour, each add-on is $25 for an additional 10k in chips.</div>
              </div>
              <div>
                <div className="fw-bold">Round Times:</div>
                <div>20-minute rounds for the first hour.</div>
                <div>15-minute rounds thereafter.</div>
              </div>
              <div>
                <div className="fw-bold">Additional Details:</div>
                <div>Cash game to follow: 2-10 kill option – NO RAKE.</div>
                <div>A breakfast menu will be available from noon to 3 PM on the day of the game, August 11th.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <hr />
          <div className="fw-bold mb-1">Weekly Poker Hours:</div>
          <div>
            <div className="fw-bold m-1">Tuesday & Saturday:</div> @ 7:15 PM
            <div>$20 buy-in, $10 add-on, 3k on-time bonus.</div>
          </div>
          <div>
            <div className="fw-bold m-1">Sunday:</div> @ 7:00 PM
            <div>$25 buy-in, $5 bounty, 1 optional add-on per entry.</div>
            <div>2-10 live game to follow - NO RAKE.</div>
          </div>
          <div>
            <div className="fw-bold m-1">School Supply Donation:</div>
            <div>Donations will continue through September 9.</div>
            <div>*List available, cash always welcome.</div>
            <div>Late entries for the first hour.</div>
          </div>
          <div className="fw-bold m-2">THERE IS NO HOUSE FEE</div>
          <div className="fw-bold m-1">ALL MONEY IS PAID OUT</div>
        </div>
      </div>
    </header>
  );
};
