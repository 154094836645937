import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";

export const Socials = () => {
  return (
    <footer className="footer">
      <div className="container-fluid text-center App header text-light ">
        <div className="footer-icons">
          <a href="https://www.facebook.com/PJsPubSpokane/" target="_blank" rel="noopener noreferrer ">
            <FaFacebook size={30} style={{ color: '#3b5998', marginRight: '10px' }}/>
          </a>
          <a href="https://www.instagram.com/pjspubspokane/?hl=en" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={30} style={{ color: '#e4405f', marginRight: '10px' }}/>
          </a>
        </div>
      </div>
    </footer>
  );
};
