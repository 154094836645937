import { Logo } from "../Logo/Logo";
import { Navbar } from "../Navbar/Navbar";
import React from "react";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark py-0">
              <div className="container-fluid ">
                <Link className="navbar-brand" to="/">
                  <Logo/>
                </Link>

        </div>
        <Navbar />
    </nav>
  );
};
