import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export const LiveMusic = () => {
  return (
    <div id="live-music" className="p-4 my-2 rounded-3 liveMusicImg">
      <div className="container text-end">
        <div className="text-center">
          <div className="row">
            <div className="col-md-6">
              <img
                src="./liveMusic.jpg"
                width="300"
                height="450"
                alt="by Felipe Portella on Unsplash"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <h1 className="display-5 fw-bold">Live Music</h1>
              <div>
                At PJ's Pub, we believe in the power of live music to bring
                people together and create unforgettable memories. That's why
                we're excited to introduce 
                <div className="fw-bold d-inline"> Live Music Nights</div> every other Thursday from 7-9pm.
                Join us to enjoy a diverse range of genres and discover new favorite artists.
              </div>

              <div className="fw-bold mt-3">Upcoming Schedule:</div>
              <ul className="list-unstyled">
                <li>Live music every other Thursday</li>
                <li>Performances from 7:00 PM to 9:00 PM</li>
              </ul>

              <div>
                Are you a singer-songwriter or musician looking to showcase your talent? We
                are always on the lookout for fresh, exciting acts to perform
                at our venue. If you're interested in performing at PJ's Pub,
                please reach out to 
                <div className="fw-bold d-inline">Jason</div>, our booking manager, at
                <div className="fw-bold d-inline"> 562-676-6535</div>.
              </div>
              <h4>Why Play at PJ's Pub?</h4>
              <ul className="list-unstyled">
                <li>Great atmosphere with a lively crowd</li>
                <li>Excellent acoustics and professional sound equipment</li>
                <li>A chance to expand your fan base</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container-fluid text-center mt-4">
          <hr />
          <div className="col-md-8 mx-auto">
            Don't miss out on the chance to be part of our live music nights.
            We can't wait to see you there!
          </div>
          <div className="col-md-8 mx-auto">
            Every other Thursday @ 7-9 PM
          </div>
        </div>
      </div>
    </div>
  );
};
