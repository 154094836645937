import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from "react-router-dom";
import { Navbar as BootstrapNavbar, Nav, NavDropdown } from "react-bootstrap";

export const Navbar = () => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLinkClick = (to, sectionId) => {
    if (sectionId) {
      // Scroll to the section before navigating
      scrollToSection(sectionId);
    }

    // Close the dropdown
    setDropdownOpen(false);

    // Navigate to the specified route
    navigate(to);
  };

  return (
    <BootstrapNavbar expand="lg" variant="dark" className="dropdown-content container-fluid">
      <BootstrapNavbar.Toggle aria-controls="navbarNavAltMarkup" />
      <BootstrapNavbar.Collapse id="navbarNavAltMarkup">
        <Nav className="navbar-nav navbar-dark">
          <Link to="/" className="nav-item nav-link active">
            Home
          </Link>
          <NavDropdown
            title="Events"
            id="basic-nav-dropdown"
            className="nav-item dropdown-content navbar-dark"
            show={dropdownOpen}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <Link onClick={() => handleLinkClick("/karaoke", 'karaoke')} className="dropdown-item custom-dropdown">
              Karaoke
            </Link>
            <Link onClick={() => handleLinkClick("/poker", 'poker')} className="dropdown-item custom-dropdown">
              Poker
            </Link>
            <Link onClick={() => handleLinkClick("/beer-pong", 'beer-pong')} className="dropdown-item custom-dropdown">
              Beer Pong
            </Link>
            <Link onClick={() => handleLinkClick("/trivia", 'trivia')} className="dropdown-item custom-dropdown">
              Trivia
            </Link>
          </NavDropdown>

          
          <Link onClick={() => handleLinkClick("/menu", 'menu')} className="nav-item nav-link">
            Menu
          </Link>
{/*           <Link onClick={() => handleLinkClick("/happenings", 'happenings')} className="nav-item nav-link">
            Happenings
          </Link> */}
          <Link onClick={() => handleLinkClick("/league", 'league')} className="nav-item nav-link">
            League
          </Link>
          <Link onClick={() => handleLinkClick("/about", 'about')} className="nav-item nav-link">
            About
          </Link>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};
