import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export const Pong = () => {
  return (
    <header id="beer-pong" className="p-4 my-2 rounded-4 pongImg">
      <div className="container">
        <h1 className="display-5 fw-bold">
          <img src="./pong.png" width="128" height="128" alt="Pong" /> Pong
        </h1>
        <div className="row">
          <div className="col-md-6">
            <div>
              Gather your A-team and bring your A-game because it's time to
              showcase your beer pong skills in a tournament like no other.
              Join us at PJ's Pub for an epic night filled with laughter,
              camaraderie, and of course, fierce beer pong competition.
            </div>
          </div>
          <div className="col-md-6">
            <img src="./pong.jpg" className="img-fluid" width="220" height="300" alt="Beer Pong" />
          </div>
        </div>

        <div className="text-center">
          <hr />
          <div className="fw-bold">Pong Hours:</div>
          <div>Thursday & Sunday @ 9 pm</div>
        </div>
      </div>
    </header>
  );
};
