import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export const Karaoke = () => {
  return (
    <header id="karaoke" className="p-4 my-2 rounded-5 karaokeImg">
      <div className="container">
        <h1 className="display-5 fw-bold">
          <img
            src="./karaoke.png"
            width="128"
            height="128"
            alt="Karaoke"
          />{" "}
          Karaoke
        </h1>
        <div className="row">
          <div className="col-md-6">
            <div>
              🎤🎉 Get ready for the ultimate Karaoke night at PJ's Pub! 🎶
              Unleash your inner rockstar and join us for a night of
              unforgettable performances, lively tunes, and fantastic company.
              Whether you're a seasoned crooner or a first-time singer, this
              is your chance to shine!
            </div>
          </div>
          <div className="col-md-3">
            <img src="./singer.jpg" className="img-fluid" alt="Singer" />
          </div>
        </div>

        <div className="text-center">
          <hr />
          <div className="fw-bold">Karaoke Hours:</div>
          <div>Friday & Saturday @ 8 pm</div>
          <div>Sunday & Wednesday @ 9 pm</div>
        </div>
      </div>
    </header>
  );
};
